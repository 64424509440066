<template>
  <div>
    <h1>关于我们</h1>
    <p>这是关于我们的页面。</p>
  </div>
</template>

<script>
export default {
  name: 'AboutComponent'
};
</script>
