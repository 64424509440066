import { createStore } from 'vuex';

export default createStore({
    state: {
        isDarkMode: false,
    },
    mutations: {
        toggleDarkMode(state) {
            state.isDarkMode = !state.isDarkMode;
        },
        setDarkMode(state, isDarkMode) {
            state.isDarkMode = isDarkMode;
        },
    },
    actions: {
        toggleDarkMode({ commit }) {
            commit('toggleDarkMode');
        },
        initializeDarkMode({ commit }) {
            const darkMode = localStorage.getItem('darkMode') === 'true';
            commit('setDarkMode', darkMode);
        },
    },
});
